import React from "react";
import { Carousel } from "react-bootstrap";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Video from "./Video";

const Slider = ({ items, ...props }) => {
  return (
    <Carousel interval={null} {...props}>
      {items.map(item => (
        <Carousel.Item key={item.alt}>
          {item.video ? (
            <Video vimeoId={item.video} size="16by9" />
          ) : (
            <div className="embed-responsive embed-responsive-16by9">
              <div className="embed-responsive-item d-block w-100">
                <PreviewCompatibleImage imageInfo={item} />
              </div>
            </div>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Slider;
