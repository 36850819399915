import React from "react";
import PropTypes from "prop-types";
import { ResponsiveEmbed } from "react-bootstrap";

const Video = ({ vimeoId, size }) => {
  return (
    <div className={`embed-responsive embed-responsive-${size}`}>
      <iframe
        src={`https://player.vimeo.com/video/${vimeoId}?title=0&amp;byline=0&amp;portrait=0`}
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        className="embed-responsive-item"
      />
    </div>
  );
};

Video.propTypes = {
  size: PropTypes.string,
  vimeoId: PropTypes.string
};

Video.defaultProps = {
  size: "16by9",
  vimeoId: null
};

export default Video;
