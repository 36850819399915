import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { Button } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Video from "../components/Video";
import Slider from "../components/Slider";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const PortfolioItemTemplate = ({
  content,
  contentComponent,
  description,
  featuredimage,
  imgBackground,
  vimeoId,
  client,
  role,
  tags,
  next,
  prev,
  slider,
  title,
  helmet
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div>
      {helmet || ""}
      {(slider && <Slider items={slider} />) ||
        (vimeoId && <Video vimeoId={vimeoId} />) ||
        (featuredimage && (
          <div
            className={`featured-thumbnail-single ${
              imgBackground ? ` bg-${imgBackground}` : ""
            }`}
          >
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `featured image thumbnail`,
                objectFit: "contain"
              }}
            />
          </div>
        ))}
      <div className="container content">
        <section className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <div className="row">
                <div className="col-12 col-md-8">
                  <PostContent content={content} />
                </div>
                <div className="col-12 col-md-4">
                  {description && (
                    <div>
                      <h2 className="h6 mb-1">Description:</h2>
                      <p>{description}</p>
                    </div>
                  )}
                  {client && (
                    <div>
                      <h2 className="h6 mb-1">Client:</h2>
                      <p>{client}</p>
                    </div>
                  )}
                  {role && (
                    <div>
                      <h2 className="h6 mb-1">Role:</h2>
                      <p>{role}</p>
                    </div>
                  )}
                  {tags && tags.length ? (
                    <div>
                      <h2 className="h6 mb-1">Tags:</h2>
                      <ul className="taglist list-unstyled">
                        {tags.map(tag => (
                          <li className="list-item" key={tag + `tag`}>
                            <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between w-100">
                {(prev && prev !== "/" && (
                  <Link to={prev} className="mr-auto">
                    <Button variant="outline-primary">&larr; Previous</Button>
                  </Link>
                )) || <span className="mr-auto" />}
                <Link to={"/"} className="mx-auto">
                  <Button variant="outline-dark">&larr; Return home</Button>
                </Link>
                {(next && (
                  <Link to={next} className="ml-auto">
                    <Button variant="outline-primary">Next &rarr;</Button>
                  </Link>
                )) || <span className="ml-auto" />}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

PortfolioItemTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  role: PropTypes.string,
  client: PropTypes.string,
  title: PropTypes.string,
  vimeoId: PropTypes.string,
  helmet: PropTypes.object
};

const PortfolioItem = ({ data, pageContext }) => {
  const { markdownRemark: post } = data;
  const { next, prev } = pageContext;
  return (
    <Layout>
      <PortfolioItemTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        client={post.frontmatter.client}
        next={next && next.fields.slug}
        prev={prev && prev.fields.slug}
        role={post.frontmatter.role}
        vimeoId={post.frontmatter.vimeoId}
        helmet={
          <Helmet titleTemplate="%s | Portfolio">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        imgBackground={post.frontmatter.imgBackground}
        featuredimage={post.frontmatter.featuredimage}
        slider={post.frontmatter.slider}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

PortfolioItem.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default PortfolioItem;

export const pageQuery = graphql`
  query PortfolioItemByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        vimeoId
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        imgBackground
        client
        role
        tags
        slider {
          alt
          video
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
